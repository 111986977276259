import React from 'react';
import styled from 'styled-components';
import logoWebp from '../assets/Logo.webp';
import logoPng from '../assets/Logo.png';

// @TODO: add background color on scroll
// background-color: var(--primary);
const Container = styled.div`
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 1;
`;

const LogoContainer = styled.div`
    display: flex;
    justify-items: center;
    align-items: center;
`;

const Brand = styled.p`
    margin: 0;
    font-family: 'Lato';
    color: var(--complementary);
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
`

const Logo = styled.img`
  width: 80px;
  height: 80px;
`;

const LogoPicture = styled.picture`
  position: absolute;
  left: 0;
`;

function Navigation() {
    return (
      <Container>
        <LogoPicture>
          <source srcSet={logoWebp} type="image/webp" />
          <Logo src={logoPng} alt={'create map marker'} />
        </LogoPicture>
        <LogoContainer>
            <Brand>Jungle</Brand>
        </LogoContainer>
      </Container>
    );
}

export default Navigation;
