import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const Container = styled.div`
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const CopyrightContainer = styled.div`
    display: flex;
    max-width: 980px;
    width: 100%;
    height: 30px;
    justify-content: center;
    align-items: center;
`;

const MenuBar = styled.nav`
    display: flex;
    max-width: 980px;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
`;

const MenuLink = styled(Link)`
    color: var(--complementary);
    text-decoration: none;
    margin: 5px;
    padding: 5px;
`;

const Copyright = styled.p`
    color: var(--complementary);
    font-size: 0.8em;
    display: block;
    padding: 0px;
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
`;

function Footer() {
  return (
    <Container>
      <MenuBar>
        <MenuLink to={'/data-privacy'}>Data privacy</MenuLink>
        <MenuLink to={'/imprint'}>Imprint</MenuLink>
      </MenuBar>
      <CopyrightContainer>
        <Copyright>Copyright &copy;2022</Copyright>
      </CopyrightContainer>
    </Container>
  );
}

export default Footer;
