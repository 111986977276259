import React from 'react';
import styled from 'styled-components';
import playstore from '../assets/google-play-badge.png';
import appstore from '../assets/apple-app-store-badge.svg';
import background from '../assets/background.jpg';

const Container = styled.div`
    background-image: var(--gradient-primary), url(${background});
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    
     @media (max-width: 768px) {
        background-image: none;
    }
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 980px;
    width: 100%;
    padding: 5px;
    text-align: center;
`;

const Image = styled.img`
    width: 100%;
`;

const Link = styled.a`
    color: white;
    text-decoration: none;
    margin: 10px;
    width: 200px;
`;

const SloganText = styled.p`
    font-size: 4em;
    line-height: 1em;
    font-weight: bold;
    color: var(--complementary);
    word-wrap: break-word;
    margin: 10px;
    text-transform: uppercase;
    font-family: "Lato-Black";
`

function Slogan() {
    return (
        <Container>
            <Row>
                <SloganText>Find your next Party<br />on Jungle </SloganText>
            </Row>
            <Row>
                <Link href="#" target="_blank">
                    <Image src={appstore} alt={'apple app store download image'} />
                </Link>
                <Link href="#" target="_blank">
                    <Image src={playstore} alt={'google play store download image'} />
                </Link>
            </Row>
        </Container>
    );
}

export default Slogan;
