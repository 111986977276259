import React from 'react';
import styled from 'styled-components';
import Slogan from '../ui/Slogan';
import Features from '../ui/Features';
import Contact from '../ui/Contact';

const Container = styled.div`
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

function Home() {
  return (
    <Container>
      <Slogan/>
      <Features/>
      <Contact/>
    </Container>
  );
}

export default Home;
