import React from 'react';
import Home from './pages/Home';
import {Route, Routes} from 'react-router-dom';
import Footer from './ui/Footer';
import Navigation from './ui/Navigation';
import Imprint from './pages/Imprint';
import DataPrivacy from './pages/DataPrivacy';
import styled from 'styled-components';
import Search from './pages/Search';
import EventDetails from './pages/EventDetails';

const Container = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

function App() {
  return (
    <div>
      <Navigation />
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/event/:id" element={<EventDetails />} />
          <Route path="/events/:search" element={<Search />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/data-privacy" element={<DataPrivacy />} />
        </Routes>
      </Container>
      <Footer />
    </div>
  );
}

export default App;
