import React from 'react';
import styled from 'styled-components';

interface Props {
    children: JSX.Element | string;
    color?: 'primary' | 'accent' | 'complementary' | 'darker-complementary';
    size?: number;
}

const Text = styled.p<{ color: 'primary' | 'accent' | 'complementary' | 'darker-complementary', size: number }>`
    color: var(--${(props) => props.color});
    font-size: ${(props) => props.size}em;
    display: block;
    padding: 25px;
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
`;

function TextBox({ children, color = 'complementary', size = 1 } : Props) {
    return (
        <Text color={color} size={size}>{children}</Text>
    );
}

export default TextBox;
