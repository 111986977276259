import React, {useEffect} from 'react';
import styled from 'styled-components';
import TextBox from '../components/TextBox';
import {useParams} from 'react-router-dom';

const Container = styled.div`
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

function Search() {
  let params = useParams<{ search: string }>();

  useEffect(() => {
    console.log(params);
  }, [params]);

  return (
    <Container>
      <div>
        <TextBox>Search</TextBox>
      </div>
    </Container>
  );
}

export default Search;
