import React from 'react';
import styled from 'styled-components';

interface Props {
    children: JSX.Element | string;
}

const Text = styled.h2`
    color: var(--complementary);
    font-family: 'PlayfairDisplay';
    font-size: 1.3em;
    display: block;
    padding: 25px;
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
`;

function Headline({ children } : Props) {
    return (
        <Text>{children}</Text>
    );
}

export default Headline;
