import React from 'react';
import styled from 'styled-components';
import TextBox from '../components/TextBox';
import {useParams} from 'react-router-dom';
import appstore from '../assets/apple-app-store-badge.svg';
import playstore from '../assets/google-play-badge.png';
import {Button} from '../components/Button';

const Container = styled.div`
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 980px;
    width: 100%;
    padding: 5px;
    text-align: center;
`;

const Image = styled.img`
    width: 100%;
`;

const Link = styled.a`
    color: white;
    text-decoration: none;
    margin: 10px;
    width: 200px;
`;

function EventDetails() {
  let params = useParams<{ id: string }>();

  return (
    <Container>
      <TextBox color={'darker-complementary'}>You have been invited to a party.</TextBox>
      <Button type={'accent'} href={`jungle://event/${params.id}`}>
        <TextBox color={'accent'}>Open party in Jungle**</TextBox>
      </Button>
      <TextBox size={0.6}>**Only works if you installed the app already</TextBox>
      <TextBox>If you don't have the app yet, you can download it below</TextBox>
      <Row>
        <Link href="#" target="_blank">
          <Image src={appstore} alt={'apple app store download image'} />
        </Link>
        <Link href="#" target="_blank">
          <Image src={playstore} alt={'google play store download image'} />
        </Link>
      </Row>
    </Container>
  );
}

export default EventDetails;
