import React from 'react';
import styled from 'styled-components';
import TextBox from '../components/TextBox';

const Container = styled.div`
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

function Imprint() {
  return (
    <Container>
      <div>
        <TextBox>Imprint</TextBox>
      </div>
    </Container>
  );
}

export default Imprint;
