import React from 'react';
import styled from 'styled-components';
import TextBox from '../components/TextBox';
import becomeHost from '../assets/become_a_host.png'
import yourProfile from '../assets/your_profile.png'
import nearbyParties from '../assets/nearby-parties.png'
import becomeHostWebp from '../assets/become_a_host.webp'
import yourProfileWebp from '../assets/your_profile.webp'
import nearbyPartiesWebp from '../assets/nearby-parties.webp'
import Headline from '../components/Headline';

const Container = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    scroll-margin-top: 60px;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 980px;
    width: 100%;
    padding: 5px;
`;

const TextCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 50%;
    justify-content: center;

    @media (max-width: 600px) {
        order: 1;
    }
`;

const ImageCol = styled(TextCol)`
    align-items: center;
    
    @media (max-width: 600px) {
        order: 2;
    }
`;

const Image = styled.img`
    max-width: 300px;
`;

const FeaturePicture = styled.picture``;


function Features() {
    return (
        <Container id="features">
            <Row>
                <TextCol>
                    <Headline>Find your next Party</Headline>
                    <TextBox>Looking for a party? There's an app for that! Jungle is the perfect way to find parties in your area or in other cities. No more boring weekends. Whether you're looking for a house party, club night, or just a group of friends to hang out with, Jungle has you covered. Just download Jungle and start finding parties today! Party on!</TextBox>
                </TextCol>
                <ImageCol>
                  <FeaturePicture>
                    <source srcSet={nearbyPartiesWebp} type="image/webp" />
                    <Image src={nearbyParties} alt={'create map marker'} />
                  </FeaturePicture>
                </ImageCol>
            </Row>
        </Container>
    );
}

export default Features;
