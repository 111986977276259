import React from 'react';
import styled from 'styled-components';
import Headline from '../components/Headline';
import {Button} from '../components/Button';

const Container = styled.div`
    background-color: var(--primary);
    background-image: var(--gradient-transparent));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    scroll-margin-top: 60px;
`;

function Contact() {
    return (
        <Container id="contact">
            <Headline>
                Want to contact us?
            </Headline>
            <Button type="accent" href="mailto:info@jungle-app.com">
                E-Mail at
                <br />
                info@jungle-app.com
            </Button>
        </Container>
    );
}

export default Contact;
